export default {
  name: "OrdersComponent",
  data() {
    return {
      heightTable:0,
      bHeightTable:0,
      dataTableProcess:[],
      dataTableFinalized:[],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      iTotalItems: 0,
      iFilter:1,
      screenHeight: 0,
      texts: "",
      tableData: [],
      arr: {},
      showTable: false,
      images: [],
      activeTab: 1,
      tab: null,
  
    
    };
  },
  beforeMount() {
    // vacia todos los filtros al momento de entrar al componente y consulta las disputas disponibles asi como el diccionario de lenguaje 
    this.$store.commit('setMenuSidebar', {active:false})
    this.$store.commit("setManagers",'');
    this.$store.commit("setClaimStatus", []);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    this.fillTable();
    this.texts = FILE.claimsTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {

    // obtiene el alto de la pagina para ponerlo en el componente empty data
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },
    // llena la tabla de disputas 
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/claim-tickets`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iFilter:this.iFilter,
          sSearch: this.sSearch,
          tStart: (this.startDate === null)? '': this.startDate,
          tEnd: (this.endDate === null)? '': this.endDate,
          sAdminOwnerId: this.sManagers,
          aClaimTicketStatus: this.claimsStatus,
          bPaid: this.paymentStatus,

        },
      })
        .then((response) => {
          this.tableData = response.data.results.map((e) =>{
            return{
              ...e,
              sSalesOrderFolioId: e.oSalesOrder.sFolioId,
              sPurchaseOrderFolioId: e.oPurchaseOrder.sFolioId,
              sVendorName: e.oVendor.sName,
              sCustomerName: e.oCustomer.sName,
              sStatusName: e.oStatus.sStatusName,

            }
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    // envia al detalle de la disputa
    detailClaims(id){
      this.$router.push(`/admin/claims-detail/${id}`);
    }
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sManagers(){
      return this.$store.state.sManagers;
    },
    claimsStatus:function(){
      return this.$store.state.aClaimStatus;
    },
    paymentStatus: function () {
      return this.$store.state.bPaymentStatus;
    },
    refreshTable() {
      return this.$store.state.refresh;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.claimsTexts[this.selectLanguage];
        this.fillTable();
      }
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.fillTable();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sManagers(){
      this.iCurrentPage = 1;
      this.fillTable();
    },
    claimsStatus:function(){
      this.iCurrentPage = 1;
      this.fillTable();
    },
    paymentStatus: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    iPageNumber: function () {
      this.fillTable();
    },
    refreshTable: function () {
      this.fillTable();
    },
    iItemsPerPage() {
      this.fillTable();
    },
    iFilter: function () {
      this.fillTable();
    },
    // consulta las disputas en base al tab seleccionado el numero 1 son las disputas en proceso y el numero 2 son las disputas finalizadas
    tab: function () {
      switch (this.tab) {
        case 0:
          this.iCurrentPage = 1;
          this.iFilter = 1
          break;
        case 1:
          this.iCurrentPage = 1;
          this.iFilter = 2
          break;
        default:
          break;
      }
    }

  },
};